
import { cva } from 'class-variance-authority'
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '24',
      validator: (value) =>
        ['16', '24', '32', '40', '48', '56', '64'].includes(value),
    },
  },
  computed: {
    styles() {
      const body = cva('', {
        variants: {
          size: {
            16: 'w-4 h-4',
            24: 'w-6 h-6',
            32: 'w-8 h-8',
            40: 'w-10 h-10',
            48: 'w-12 h-12',
            56: 'w-14 h-14',
            64: 'w-16 h-16',
          },
        },
      })
      return body({ size: this.size })
    },
  },
}

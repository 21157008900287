
export default {
  name: 'TabsTop',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    tabContentParentSelector: {
      type: String,
      default: '',
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
    isAnchorTabs: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    underline: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: 0,
      isContentVisibleOnScreen: true,
      tabContentParentObserver: null,
    }
  },
  mounted() {
    if (this.isSticky && this.isAnchorTabs) {
      this.observeTabParent()
      document.addEventListener('scroll', this.updateActiveTab)
      this.updateActiveTab()
    }
  },
  unmounted() {
    if (this.tabContentParentObserver) {
      this.tabContentParentObserver.disconnect()
    }

    if (this.isSticky && this.isAnchorTabs) {
      document.removeEventListener('scroll', this.updateActiveTab, {
        passive: true,
      })
    }
  },
  methods: {
    observeTabParent() {
      this.tabContentParentObserver = new IntersectionObserver(
        (entries) => {
          this.isContentVisibleOnScreen = entries.some(
            (entry) => entry.isIntersecting,
          )
        },
        { threshold: 0 },
      )

      const el = document.querySelector(this.tabContentParentSelector)

      if (el) {
        this.tabContentParentObserver.observe(el)
      }
    },
    updateActiveTab() {
      const sections = this.tabs
        .map((tab) =>
          Array.from(document.querySelectorAll(`[data-group="${tab}"]`)),
        )
        .flat()

      if (!sections.length) {
        return
      }

      const sectionCenters = sections.map((section) => {
        const rect = section.getBoundingClientRect()
        return {
          center: rect.top + rect.height / 2,
          group: section.dataset.group,
        }
      })

      const screenCenter = window.innerHeight / 2

      const mostVisibleSection = sectionCenters.reduce((acc, curr) => {
        const accDiff = Math.abs(acc.center - screenCenter)
        const currDiff = Math.abs(curr.center - screenCenter)

        return accDiff < currDiff ? acc : curr
      })

      this.active = this.tabs.indexOf(mostVisibleSection.group)
    },

    setActive(val) {
      this.active = val
      this.$emit('setActive', val)
    },
  },
}


import { linkMixin } from '~/mixins'
export default {
  // Grey background image on top
  name: 'DsRepresentativeFour',
  mixins: [linkMixin],
  props: {
    image: {
      type: Object,
      default: () => {},
    },
    logo: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
    hashTags: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    linkUrl: {
      type: String,
      default: '',
    },
  },
}

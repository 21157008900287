
import { getHyperlinkInfo } from 'utils'
const NUXT_LINK = 'NuxtLink'
const A_TAG = 'a'
export default {
  name: 'Hyperlink',
  props: {
    link: {
      type: String,
      default: '',
    },
    as: {
      type: String,
      default: '',
      validator: (value) => [NUXT_LINK, A_TAG, ''].includes(value),
    },
  },
  computed: {
    dynamicAttributes() {
      const runningOrigin = this.$config.domain
      const { isExternal, link } = getHyperlinkInfo(runningOrigin, this.link)
      const linkAs = this.as || (isExternal ? A_TAG : NUXT_LINK)
      const linkAttributes = {
        [NUXT_LINK]: {
          to: this.localePath(link),
        },
        [A_TAG]: {
          href: link,
          target: '_blank',
        },
      }
      return { ...linkAttributes[linkAs], ...this.$attrs, is: linkAs }
    },
  },
}


export default {
  name: 'AppButtons',
  props: {
    googleLink: {
      type: String,
      default: '',
    },
    appleLink: {
      type: String,
      default: '',
    },
  },
}

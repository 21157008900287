
export default {
  name: 'TwoColumnsImageContent',
  props: {
    image: {
      type: Object,
      default: () => {},
    },
    videoLink: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showImage() {
      return (
        this.image &&
        typeof this.image === 'object' &&
        Object.keys(this.image).length &&
        !this.videoLink
      )
    },
  },
}

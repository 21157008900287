
// Standard
export default {
  name: 'HeroOne',
  props: {
    topText: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    bgImageUrl: {
      type: String,
      default: '',
    },
    overlay: {
      type: Boolean,
      default: false,
    },
  },
}


import { blogsAndNews } from '@/queries/news/newsPosts.gql'

// data indexes are returned in different order than in other pages...
const formatGalleryData = (galleryData = []) => {
  if (!galleryData || !galleryData.length) return
  const items = []
  galleryData.forEach((item) => {
    if (item.section_code === 'flespi_image') {
      const obj = {
        image: item?.data?.[1]?.value || {},
        title: item?.data?.[2]?.value || '',
        type: 'image',
      }
      items.push(obj)
    }
  })
  return items
}

export default {
  name: 'EnergyLanding',
  async asyncData({ app, i18n, $config }) {
    const { data } = await app.$gqlQuery({
      query: blogsAndNews,
      fetchPolicy: 'reload',
      variables: {
        postsLimit: 3,
        domainId: $config.domainId,
        languageCode: i18n.locale,
      },
    })

    return {
      latestNews: data?.latestNews?.data || false,
    }
  },
  data() {
    return {
      pageData: {},
      latestNews: [],
      modelsActiveTab: 0,
    }
  },
  computed: {
    heroBackgroundImage() {
      return this.pageData?.bgImage?.extras?.e_replaced?.webp?.src || ''
    },
    sectionOneImage() {
      return this.pageData?.files['front.energy.lander.section_1.image'] || {}
    },
    sectionTwoImage() {
      return (
        this.pageData?.files['front.energy.lander.section_2.image']?.extras
          ?.e_replaced?.webp?.src || ''
      )
    },
    sectionThreeImage() {
      return this.pageData?.files['front.energy.lander.section_3.image'] || {}
    },
    sectionFourImage() {
      return (
        this.pageData?.files['front.energy.lander.section_4.image']?.extras
          ?.e_replaced?.webp?.src || ''
      )
    },
    galleryImages() {
      return formatGalleryData(this.pageData.front_sections?.sections)
    },
    supportCards() {
      return [
        {
          title: this.$t('front.energy.lander.support.card_1.title'),
          text: this.$t('front.energy.lander.support.card_1.text'),
          link: this.$t('front.energy.lander.support.card_1.link'),
        },
        {
          title: this.$t('front.energy.lander.support.card_2.title'),
          text: this.$t('front.energy.lander.support.card_2.text'),
          link: this.$t('front.energy.lander.support.card_2.link'),
        },
        {
          title: this.$t('front.energy.lander.support.card_3.title'),
          text: this.$t('front.energy.lander.support.card_3.text'),
          link: this.$t('front.energy.lander.support.card_3.link'),
        },
      ]
    },

    models() {
      return [
        {
          title: this.$t('front.energy.lander.models.block_1.title'),
          text: this.$t('front.energy.lander.models.block_1.text'),
          ctaText: this.$t('front.energy.lander.models.block_1.cta_text'),
          ctaLink: this.$t('front.energy.lander.models.block_1.cta_link'),
          image:
            this.pageData?.files['front.energy.lander.models.block_1.image'] ||
            {},
        },
        {
          title: this.$t('front.energy.lander.models.block_2.title'),
          text: this.$t('front.energy.lander.models.block_2.text'),
          ctaText: this.$t('front.energy.lander.models.block_2.cta_text'),
          ctaLink: this.$t('front.energy.lander.models.block_2.cta_link'),
          image:
            this.pageData?.files['front.energy.lander.models.block_2.image'] ||
            {},
        },
        {
          title: this.$t('front.energy.lander.models.block_4.title'),
          text: this.$t('front.energy.lander.models.block_4.text'),
          ctaText: this.$t('front.energy.lander.models.block_4.cta_text'),
          ctaLink: this.$t('front.energy.lander.models.block_4.cta_link'),
          image:
            this.pageData?.files['front.energy.lander.models.block_4.image'] ||
            {},
        },
        {
          title: this.$t('front.energy.lander.models.block_3.title'),
          text: this.$t('front.energy.lander.models.block_3.text'),
          ctaText: this.$t('front.energy.lander.models.block_3.cta_text'),
          ctaLink: this.$t('front.energy.lander.models.block_3.cta_link'),
          image:
            this.pageData?.files['front.energy.lander.models.block_3.image'] ||
            {},
        },
      ]
    },
    modelsTabTitles() {
      return this.models.map((item) => item.title)
    },
    activeModel() {
      return this.models[this.modelsActiveTab]
    },
  },
  created() {
    this.pageData = this.$store.state.page.content
  },
  methods: {
    newsletterError(validation) {
      const { $error, required, email } = validation.form.email
      if ($error && required)
        return this.$i18n.t('form.error.email', {
          name: this.$i18n.t('form.email'),
        })
      else if ($error && email)
        return this.$i18n.t('form.error.field_required', {
          name: this.$i18n.t('form.email'),
        })
      return ''
    },
  },
}


import { youtubeThumbUrl } from '@/utils'
export default {
  name: 'Video',
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  methods: {
    youtubeThumbUrl,
  },
}
